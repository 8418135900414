<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="$t('modals.createPersonalizationSet.microsoft.chooseContactField') + '*'"
        >
          <treeselect
            v-model="treeItems"
            :multiple="true"
            :flat="true"
            :options="contactFields"
            :clearable="false"
            placeholder="Select a contact field"
            @select="selectItem"
            @deselect="deselectItem"
          >
            <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
          </treeselect>
        </b-form-group>
        <p class="font-italic">
          {{ $t('modals.createPersonalizationSet.microsoft.chooseContactFieldDesc') }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto" class="mr-auto">
        <b-button
          size="lg"
          @click="$emit('prev-step')"
          class="green-button border-0 mt-2"
        >
          {{ $t('modals.createPersonalizationSet.back') }}
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          size="lg"
          type="submit"
          class="green-button border-0 mt-2"
          :disabled="treeItems.length === 0"
          @click="next"
        >
          {{ $t('modals.createPersonalizationSet.next') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import GetMicrosoftCRMContactsFields from '@/graphQlQueries/queries/getMicrosoftCRMContactsFields';

import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'PersonalizationMicrosoftStepThree',
  components: { Treeselect },
  props: {
    contactFieldsProp: {
      type: Array,
      required: true,
    },
    marketingListId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    treeItems: [],
    contactFields: [],
  }),
  created() {
    this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
    this.treeItems = this.contactFieldsProp;
  },
  apollo: {
    contactFields: {
      variables() {
        return {
          marketingListId: this.marketingListId,
        };
      },
      query: GetMicrosoftCRMContactsFields,
      update(data) {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        if (data.getMicrosoftCRMContactsFields.length === 0) {
          return [];
        }
        // safe the all contact fields for schema creation later
        this.allCRMContactFields = data.getMicrosoftCRMContactsFields;
        return data.getMicrosoftCRMContactsFields.map((item) => ({
          id: item.fieldName,
          label: item.fieldName,
        }));
      },
      skip() {
        return !this.marketingListId;
      },
    },
  },
  methods: {
    next() {
      console.log('next', this.treeItems);
      this.$emit('next-step', this.treeItems, this.allCRMContactFields);
    },
    selectItem() {
      console.log('item selected');
    },
    deselectItem() {
      console.log('item deselected');
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body h4 {
  font-size: 15px;
  font-weight: bold;
  color: #44484C;
}

ul {
  padding-left: 0;
  list-style: none;

  li {
    color: #44484C;

    /deep/ svg {
      color: #B9B9B9;

      &.active {
        color: #53DC86;
      }
    }

    &.past-step {
      cursor: pointer;
    }
  }
}

div.step-1 {
  min-height: 120px;
}

.grey-line {
  height: 30px;
  border-left: 1px solid #B9B9B9;
  margin-left: 6px;
}

.show-url-icon svg {
  font-size: 60px;
}

.show-url {
  font-size: 1.1rem;
}

.loading-spinner {
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-spinner {
  position: absolute;
  inset: 0px;
  opacity: 0.85;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}
.disabled {
  cursor: not-allowed;
}
</style>
