import gql from 'graphql-tag';

export default gql`
query getMicrosoftCRMContactsFields($marketingListId: String!) {
  getMicrosoftCRMContactsFields(
    object: {
      marketingListId: $marketingListId
    }
  ) {
    fieldName
    fieldType
  }
}
`;
